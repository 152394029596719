import * as React from "react"
import Svg, { Circle } from "react-native-svg"

const ColorblockShoesIcon = (props) => {
  return (
    <Svg
      viewBox="0 0 4320 4320"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
      height={props.height}
      width={props.width}
    >
      <Circle
        cx={681.463}
        cy={2239.54}
        r={419.776}
        transform="translate(-1215.69 -5080.94) scale(3.35229) translate(1083.28 1079.13) scale(.48263)"
        fill="#ffdc14"
      />
      <Circle
        cx={681.463}
        cy={2239.54}
        r={419.776}
        transform="translate(-1215.69 -5080.94) scale(3.35229) translate(678.086 1079.13) scale(.48263)"
        fill="#3059ff"
      />
      <Circle
        cx={681.463}
        cy={2239.54}
        r={419.776}
        transform="translate(-1215.69 -5080.94) scale(3.35229) translate(272.893 1079.13) scale(.48263)"
        fill="#ff4248"
      />
    </Svg>
  )
}

export default ColorblockShoesIcon