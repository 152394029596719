import * as React from "react"
import Svg, { Path, Ellipse } from "react-native-svg"

const LogoIcon = (props) => {
  return (
    <Svg
      viewBox="0 0 4320 4320"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width={props.width}
      height={props.height}
      {...props}
    >
      <Path
        d="M506 3110.82l-35 16.33v-293.53l35-16.33v293.53z"
        transform="translate(3 -97.5) rotate(25.012 238.465 3026.176)"
        fill="#ff0"
      />
      <Path
        d="M3420 1367.4c0-48.79-40.03-88.4-89.33-88.4H978.33c-49.303 0-89.33 39.61-89.33 88.4v1591.2c0 48.79 40.027 88.4 89.33 88.4h2352.34c49.3 0 89.33-39.61 89.33-88.4V1367.4z"
        transform="translate(3 -97.5) matrix(.97896 0 0 .98925 52.34 32.745)"
        fill="#8c00bf"
      />
      <Path
        d="M569.23 2951H3987.5c36.7 0 66.5 29.8 66.5 66.5v133c0 36.7-29.8 66.5-66.5 66.5H445.122l124.108-266zm-38.622-1l-124.109 266H326.5c-36.702 0-66.5-29.8-66.5-66.5v-133c0-36.7 29.798-66.5 66.5-66.5h204.108z"
        transform="translate(3 -97.5)"
        fill="#ff8000"
      />
      <Path
        d="M3668 2607.75c0-52.85-42.9-95.75-95.75-95.75h-191.5c-52.85 0-95.75 42.9-95.75 95.75v254.5c0 52.85 42.9 95.75 95.75 95.75h191.5c52.85 0 95.75-42.9 95.75-95.75v-254.5z"
        transform="translate(3 -97.5)"
        fill="#00bf00"
      />
      <Ellipse
        cx={1043}
        cy={1392}
        rx={19}
        ry={17}
        transform="translate(3 -97.5) matrix(.94737 0 0 1.05882 6.895 -77.882)"
        fill="#fff"
      />
    </Svg>
  )
}

export default LogoIcon