import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Surface, TouchableRipple } from 'react-native-paper'

const AppIconWrapper = (props) => {
  return (
    <Surface style={[styles.surface,]}>
      <View>
        {props.children}
      </View>
    </Surface>
  )
}

export default AppIconWrapper

const styles = StyleSheet.create({
  surface: {
    borderRadius: '25%',
    height: 57,
    width: 57,
  },
})