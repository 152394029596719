import * as React from "react"
import Svg, { Path } from "react-native-svg"

const RecordsIcon = (props) => {
  return (
    <Svg
      viewBox="0 0 4230 4320"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
      height={props.height}
      width={props.width}
    >
      <Path
        d="M1927.63 2937.33c-79.93-9.13-154.19-24.06-222.79-44.8-122.47-37.03-223.83-84.5-304.05-142.41-80.23-57.92-143.37-121.29-189.41-190.13-46.05-68.83-80.95-151.91-104.68-249.23-23.74-97.31-35.6-209.11-35.6-335.38V1222h2087.81v719.2c0 162.35-9.97 286.25-29.91 371.7-27.54 119.63-77.38 221.7-149.54 306.2-93.04 110.13-211.96 192.49-356.75 247.08-97.3 36.69-203.92 61.05-319.85 73.08l-70.47-278.81c169.47-9.7 303.37-44.61 401.71-104.73 117.26-71.68 195.83-158.79 235.7-261.33 28.48-74.06 42.73-193.21 42.73-357.47v-438.63H1317.47v445.76c0 137.66 12.82 245.66 38.46 323.99 25.63 78.33 61.71 140.76 108.23 187.28 65.51 65.51 153.57 116.54 264.18 153.09 78.18 25.84 168.1 42.54 269.75 50.12l-70.46 278.8z"
        transform="translate(.048 147.935)"
        fill="#ff0"
        fillRule="nonzero"
      />
      <Path
        d="M2121 1189h1037.81v719.2c0 162.35-9.97 286.25-29.91 371.7-27.53 119.63-77.38 221.7-149.53 306.2-93.05 110.13-211.97 192.49-356.75 247.08-99.55 37.54-208.85 62.17-327.89 73.9l-70.57-279.19c173.31-9.03 309.94-44.09 409.85-105.17 117.25-71.68 195.82-158.79 235.7-261.33 28.48-74.06 42.72-193.21 42.72-357.47v-438.63H1317.38v445.76c0 137.66 12.82 245.66 38.45 323.99 25.63 78.33 61.71 140.76 108.24 187.28 65.51 65.51 153.57 116.54 264.18 153.09 80.29 26.54 172.96 43.44 278.04 50.71l-70.55 279.12c-83.06-9.02-160.05-24.26-230.99-45.71-122.48-37.03-223.83-84.5-304.06-142.41-80.23-57.92-143.36-121.29-189.41-190.13-46.05-68.83-80.94-151.91-104.68-249.23-23.73-97.31-35.6-209.11-35.6-335.38V1189h1038l6 12 6-12z"
        transform="translate(.048 147.935)"
        fill="#00bf00"
        fillRule="nonzero"
      />
      <Path
        d="M2143 1145h1015.81v719.2c0 162.35-9.97 286.25-29.91 371.7-27.53 119.63-77.38 221.7-149.53 306.2-93.05 110.13-211.97 192.49-356.75 247.08-102.56 38.67-215.47 63.65-338.75 74.93l-70.69-279.68c178.54-8.11 318.82-43.35 420.83-105.71 117.25-71.68 195.82-158.79 235.7-261.33 28.48-74.06 42.72-193.21 42.72-357.47v-438.63H1317.38v445.76c0 137.66 12.82 245.66 38.45 323.99 25.63 78.33 61.71 140.76 108.24 187.28 65.51 65.51 153.57 116.54 264.18 153.09 83.06 27.46 179.38 44.6 288.97 51.44l-70.65 279.53c-87.21-8.86-167.81-24.47-241.82-46.85-122.48-37.03-223.83-84.5-304.06-142.41-80.23-57.92-143.36-121.29-189.41-190.13-46.05-68.83-80.94-151.91-104.68-249.23-23.73-97.31-35.6-209.11-35.6-335.38V1145h1016l28 56 28-56z"
        transform="translate(.048 147.935)"
        fill="#ff8000"
        fillRule="nonzero"
      />
      <Path
        d="M2173.07 1084.87h985.84v719.2c0 162.35-9.97 286.25-29.91 371.7-27.54 119.63-77.38 221.69-149.54 306.19-93.04 110.14-211.96 192.5-356.75 247.09-106.69 40.23-224.6 65.64-353.72 76.22l-70.86-280.36c185.78-6.77 331.1-42.21 435.97-106.32 117.26-71.68 195.83-158.8 235.7-261.33 28.48-74.06 42.73-193.21 42.73-357.47v-438.64H1317.47v445.76c0 137.67 12.82 245.67 38.46 324 25.63 78.33 61.71 140.75 108.23 187.27 65.51 65.52 153.57 116.55 264.18 153.1 86.82 28.69 188.11 46.12 303.87 52.29l-70.8 280.1c-92.86-8.54-178.39-24.64-256.57-48.27-122.47-37.03-223.83-84.5-304.05-142.42-80.23-57.91-143.37-121.29-189.41-190.12-46.05-68.83-80.95-151.91-104.68-249.23-23.74-97.31-35.6-209.11-35.6-335.38v-753.38h985.83L2115 1201l58.07-116.13z"
        transform="translate(.048 147.935)"
        fill="#8c00bf"
        fillRule="nonzero"
      />
    </Svg>
  )
}

export default RecordsIcon