import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import LogoIcon from '../icons/LogoIcon'

const Footer = (props) => {
  return (
    <View>
      <View style={[styles.flexOne, {margin: 10,}]}>
        <View style={[styles.alignItemsCenter, styles.flexDirectionRow, styles.flexWrap,]}>
          <View style={[styles.flexDirectionRow,]}>
            <View>
              <LogoIcon height={50} width={50}/>
            </View>
            <View style={{margin: 10, width: 300,}}>
              <Text>be good, make good choices.</Text>
              <Text style={{fontSize: 8, lineHeight: 8, marginVertical: 2.5,}}>© NDV.</Text>
            </View>
          </View>
          <View style={[styles.flexOne,]}>
            <View style={[styles.flexDirectionRow, styles.flexWrap, styles.justifyContentFlexEnd,]}>
              <TouchableRipple style={{padding: 2.5, borderRadius: 5,}} onPress={() => {}}>
                <Text style={{margin: 2.5, textTransform: 'uppercase', fontSize: 10,}}>Privacy Policy</Text>
              </TouchableRipple>
              <TouchableRipple style={{padding: 2.5, borderRadius: 5,}} onPress={() => {}}>
                <Text style={{margin: 2.5, textTransform: 'uppercase', fontSize: 10,}}>Returns Policy</Text>
              </TouchableRipple>
              <TouchableRipple style={{padding: 2.5, borderRadius: 5,}} onPress={() => {}}>
                <Text style={{margin: 2.5, textTransform: 'uppercase', fontSize: 10,}}>Terms & Conditions</Text>
              </TouchableRipple>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Footer

const styles = StyleSheet.create({
  alignItemsCenter: {
    alignItems: 'center',
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  flexOne: {
    flex: 1,
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },
})