import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import AppIconWrapper from '../submodules/AppIconWrapper'
import ColorblockShoesIcon from '../icons/ColorblockShoesIcon'
import RecordsIcon from '../icons/RecordsIcon'
import { TouchableRipple } from 'react-native-paper'

const AppPortfolio = (props) => {
  return (
    <View style={[styles.flexDirectionRow, styles.flexWrap,]}>
      <View style={{margin: 5,}}>
        <AppIconWrapper>
          <TouchableRipple style={{borderRadius: '25%'}} onPress={() => {}}>
            <View style={{padding: 5,}}>
              <ColorblockShoesIcon height="100%" width="100%" />
            </View>
          </TouchableRipple>
        </AppIconWrapper>
      </View>
      <View style={{margin: 5,}}>
        <AppIconWrapper>
          <TouchableRipple style={{borderRadius: '25%'}} onPress={() => {}}>
            <View style={{padding: 5,}}>
              <RecordsIcon height="100%" width="100%" />
            </View>
          </TouchableRipple>
        </AppIconWrapper>
      </View>
    </View>
  )
}

export default AppPortfolio

const styles = StyleSheet.create({
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
})