import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

const BotIcon = (props) => {
  return (
    <Svg
      viewBox="0 0 4320 4320"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
      height={props.height}
      width={props.width}
    >
      <Circle
        cx={3185}
        cy={2731}
        r={287}
        transform="translate(-1526.53 -1504.68) scale(1.69661) translate(-1714 273)"
        fill="#ff8000"
      />
      <Circle
        cx={3185}
        cy={2731}
        r={287}
        transform="translate(-1526.53 -1504.68) scale(1.69661) translate(-26 -70)"
        fill="#ff8000"
      />
      <Path
        d="M2272.26 3440.79c-58.06 8.05-117.43 12.21-177.76 12.21C1383.91 3453 807 2876.09 807 2165.5c0-589.36 396.86-1086.77 937.64-1239.301l226.3 199.391 234.77-242.825C2864.3 939.178 3382 1492.36 3382 2165.5c0 513.9-301.73 957.88-737.57 1164.28l-457.98-1.67 85.81 112.68z"
        transform="translate(-1526.53 -1504.68) scale(1.69661) matrix(.97884 0 0 .97884 109.83 40.333)"
        fill="#8c00bf"
      />
      <Circle
        cx={1593}
        cy={1939}
        r={336}
        transform="translate(-1526.53 -1504.68) scale(1.69661) translate(1053 -104)"
        fill="#fff"
      />
      <Circle
        cx={1593}
        cy={1939}
        r={336}
        transform="translate(-1526.53 -1504.68) scale(1.69661) translate(27 -104)"
        fill="#fff"
      />
      <Path
        d="M1928.07 1372.14c-78.66 39.46-161.13 57.17-245.84 50.06-26.17-2.19-52.04-6.71-77.59-13.49L1775.5 1067l152.57 305.14z"
        transform="translate(-1526.53 -1504.68) scale(1.69661) matrix(-1.3984 .05596 -.02672 -.66759 4550.41 1755.05)"
        fill="#00bf00"
      />
      <Path
        d="M2555.08 3397.76c-18.53 66.25-50.38 131.1-95.98 192.24H2360l195.08-192.24z"
        transform="translate(-1526.53 -1504.68) scale(1.69661) matrix(.8476 1.11298 -1.47185 1.12092 5533.6 -3352.71)"
        fill="#ff0"
      />
    </Svg>
  )
}

export default BotIcon
