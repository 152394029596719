import React from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { Appbar, Text } from 'react-native-paper'

const Header = (props) => {
  return (
    <SafeAreaView>
      <Appbar style={[styles.appbar]}>
        <Appbar.Content subtitle="the online boutique." />
        <Appbar.Action icon="account-circle-outline" onPress={() => {}} />
      </Appbar>
    </SafeAreaView>
  )
}

export default Header

const styles = StyleSheet.create({
  appbar: {
    elevation: 0,
  },
})