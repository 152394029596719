// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8ZiafBJJVBQbgRgRPFFHzHafV4qSs-lY",
  authDomain: "com-diztro.firebaseapp.com",
  projectId: "com-diztro",
  storageBucket: "com-diztro.appspot.com",
  messagingSenderId: "440209666658",
  appId: "1:440209666658:web:88a9e57b35c94ecb93573b"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
console.log("App initialized.", app);
export const db = getFirestore(app);
console.log('Database initialized.', db);
const functions = getFunctions(app);
console.log("Functions initialized.", functions);