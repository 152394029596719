import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Headline, Subheading, Text } from 'react-native-paper'
import AppPortfolio from '../../components/modules/AppPortfolio'
import LayoutTemplate from '../../components/templates/LayoutTemplate'
import BotIcon from '../../components/icons/BotIcon'

const HomeScreen = (props) => {
  return (
    <LayoutTemplate>
      <View style={[styles.container,]}>
        <View>
          <BotIcon height={20} width={20}/>
        </View>
        <Headline style={[styles.headline,]}>diztro</Headline>
        <View style={[styles.flexDirectionRow,]}>
          <Subheading>our portfolio</Subheading>
        </View>
        <View style={{margin: 20,}}>
          <AppPortfolio />
        </View>
      </View>
    </LayoutTemplate>
  )
}

export default HomeScreen

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flexOne: {
    flex: 1,
  },
  headline: {
    fontSize: 88,
    fontWeight: 'bold',
    lineHeight: 88,
  },
})